<template>
  <div  class="content ma-0 mb-16 px-0 px-sm-16  mx-sm-12" >

    <div class="text-h5 font-weight-bold text-center mt-md-4 pt-14 pb-6" >
      <div style="position: relative;">TRENDS & STORIES
        <Share />
        <!--<v-menu offset-y nudge-top=-10 >
          <template v-slot:activator="{ on, attrs }">
          <v-btn plain  class="mr-0 pr-0 mr-md-10 mt-3" absolute right height=25 v-bind="attrs" v-on="on">
            <v-icon size=20>mdi-share-variant-outline</v-icon> <span v-if="!$vuetify.breakpoint.mobile" >分享此页面</span>
          </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="copyurl">
              <v-list-item-title>复制链接</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$util.goSina()">
              <v-list-item-title>新浪微博</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-content>
              <v-list-item-title>
                微信扫一扫
                <v-img :src="shareImg" width="90"></v-img>
              </v-list-item-title>
              
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>-->
      </div>
      <div>潮流专题</div>
      

    </div>

    <div class="">
      <v-row>
        <v-col cols="12" md="6" class="pa-4" v-for="item in toplist" :key="item.topic_id" style="height:518px">
          <div class="top-item">
            <div class="top-img pointer" @click="$router.push('/topicdetail?id='+item.topic_id)">
              <v-img :src="imgdomain+item.image" class="white--text align-end" height="420" >
                <div class="top-title text-h5 font-weight-bold  pl-4 ">{{item.title}}</div>
                <div class="top-subtitle  pl-4 mb-4">{{item.subtitle}}</div>
              </v-img>
            </div>
            <!--<div class="top-title text-h5 pa-4 text-center" style="height:96px;">{{item.title}}</div>
            <div class="top-subtitle grey--text text-center pb-2">{{item.subtitle}}</div>-->
            <div class="top-btn text-start my-4">
              <v-btn  dark outlined width="40%" tile class="main-btn" @click="$router.push('/topicdetail?id='+item.topic_id)">查看更多</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="moretitle mt-8 mt-md-12 mb-md-12 pb-1 pl-1 font-weight-bold">MORE 往期专题</div>

    <v-container class="pa-0 ma-0 mt-3" >
      <v-row>
        <v-col class="mt-0" cols="6" md="4" v-for="item in topiclist" :key="item.topic_id" style="height:350px">
          <div class="topic-item pointer" v-if="item.uid==0" @click="gotoDetail(item)">
            <!--<div class="topic-item d-flex flex-column justify-space-between" v-if="true" >-->
              <div class="top-img">
                <v-img :src="imgdomain+item.image"  class="white--text align-end" height="325">
                  <div class="top-title topic-title pl-4 ">{{item.title}}</div>
                  <div class="top-subtitle  pl-4 mb-4">{{item.subtitle}}</div>
                </v-img>
              </div>
            
            
          </div>
          <div class="topic-item d-flex grey lighten-3 flex-column justify-space-between" v-else>
            <div class="top-img pointer" @click="gotoDetail(item)">
              <v-img :src="imgdomain+item.image" max-height="170" :aspect-ratio="1/1"></v-img>
            </div>
            <div class="top-title topic-title pa-3 text-center" >{{item.title}}</div>
            <!--<div class="top-subtitle grey--text text-center pb-2" v-if="item.subtitle!=''">{{item.subtitle}}</div>-->
            <div class="top-subtitle grey--text text--darken-2 text-center pb-1">{{item.nick_name}}</div>
            <div class="text-center showdetailbtn">
              <v-btn  light plain :ripple=false  tile class="morebtn" @click="gotoDetail(item)">查看详情</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div  class="pa-5 py-md-12 text-center">
      <v-btn  dark outlined width="30%" tile class="main-btn" @click="update">加载更多内容</v-btn>
      <div><v-icon>mdi-chevron-down</v-icon></div>
    </div>


    <div id="bottomarea"></div>


  </div>
</template>

<script>

import topicApi from '@/api/topic'
import { mapState } from 'vuex'
import Share from '@/components/Share';


export default {

  components: {
      Share
  },
  data: () => ({
    shareImg :"",
    scrollTop:0,
    isLoaded:false,
    page : 2,
    toplist : [],
    topiclist : []

    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
      imgdomain: state => state.app.imgdomain,
      apidomain: state => state.app.apidomain,
      uid: state => state.user.uid,
    }),
    

    
  },
  //watch : { scrollTop : "scroll" },


  mounted() {


    this.$util.setTitle("潮流专题")
    topicApi.topiclist({page_size:20}).then(res => {
      this.isLoaded = true
      this.topiclist = res.data.data

      let item =null
      for (let i=0; i<this.topiclist.length; i++) {
        
        item = this.topiclist[i]
        
        if (item.uid==0 && this.toplist.length<2) {
          this.toplist.push(item)
          this.topiclist.splice(i, 1); 
          i--
        }
      }
      this.$util.weixinShare(window.location.href, document.title)
      /*this.topiclist.forEach((item,i) => {
        console.log('aa',item.topic_id)
        if (item.uid==0 && this.toplist.length<2) {
          this.toplist.push(item)
          this.topiclist.splice(i, 1); 
        }
      })*/

      
    })


    


    
  },
  destroyed() {
    console.log("destroyed")
    //window.removeEventListener('scroll', this.scroll)
  },
  methods: {
    homesearch() {
      alert(3)
    },
    
    async scroll () {
      const elOffsetTop = document.getElementById("bottomarea").offsetTop
      const docScrollTop = document.documentElement.scrollTop - 230
      if (elOffsetTop >= docScrollTop && elOffsetTop < (docScrollTop + window.innerHeight) && this.isLoaded) {
        this.update()
      }
    },
    gotoDetail(item) {
      let query = {id:item.topic_id}
      
      this.$router.push( {
        path:'/topicdetail',
        query: query
      })
    },

    update() {
      this.isLoaded = false
      topicApi.topiclist({page_index:this.page,page_size:20}).then(res => {
        this.isLoaded = true
        this.page++
        
        res.data.data.forEach((item) => {
          
            this.topiclist.push(item)

          
          //
        })


        
      })
    }
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.moretitle {
  font-size:1.1rem;
  border-bottom:1px solid black;
}
.topic-item {
  height: 100%;
}
/deep/ .showdetailbtn span{
  border-bottom:1px solid black;
  padding-bottom:0px;
  font-size: 0.8rem;
  font-weight: 400px !important;
  opacity:1 !important;
}
/deep/ .morebtn:active{
  opacity:0.5 !important;
}
</style>

<style lang="sass">


</style>